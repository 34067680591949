<script lang="ts" setup>
import { Popover, PopoverPanel } from "@headlessui/vue";

const navigation = [
  { name: "Product", href: "#product" },
  { name: "Pricing", href: "#pricing" },
  { name: "Company", href: "#company" },
  { name: "Contact Us", href: "#support" },
];
</script>

<template>
  <div class="relative overflow-hidden bg-gray-900 isolate">
    <div class="hidden sm:absolute sm:inset-0 sm:block" aria-hidden="true">
      <LazyBackground />
    </div>
    <div class="relative pt-6 pb-16 sm:pb-24">
      <Popover>
        <ClientOnly>
          <TopNavBar :navigation="navigation" />
        </ClientOnly>

        <transition
          enter-active-class="duration-150 ease-out"
          enter-from-class="scale-95 opacity-0"
          enter-to-class="scale-100 opacity-100"
          leave-active-class="duration-100 ease-in"
          leave-from-class="scale-100 opacity-100"
          leave-to-class="scale-95 opacity-0">
          <PopoverPanel
            focus
            class="absolute inset-x-0 top-0 z-10 p-2 transition origin-top-right transform md:hidden">
            <LazyPopoverMenuItems :navigation="navigation" />
          </PopoverPanel>
        </transition>
      </Popover>

      <div
        class="absolute left-[calc(50%-4rem)] top-10 -z-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]"
        aria-hidden="true">
        <div
          class="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20"
          style="
            clip-path: polygon(
              73.6% 51.7%,
              91.7% 11.8%,
              100% 46.4%,
              97.4% 82.2%,
              92.5% 84.9%,
              75.7% 64%,
              55.3% 47.5%,
              46.5% 49.4%,
              45% 62.9%,
              50.3% 87.2%,
              21.3% 64.1%,
              0.1% 100%,
              5.4% 51.1%,
              21.4% 63.9%,
              58.9% 0.2%,
              73.6% 51.7%
            );
          " />
      </div>
      <div
        class="px-6 pt-10 pb-24 mx-auto max-w-7xl sm:pb-32 lg:flex lg:px-8 lg:py-24">
        <LazyIntroLeft />
        <div
          class="flex max-w-2xl mx-auto mt-16 sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
          <div class="flex-none max-w-3xl sm:max-w-5xl lg:max-w-none">
            <LazyNuxtImg
              src="/homepage-screen-close.png"
              alt="App screenshot"
              height="1442"
              class="w-[76rem] rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
