<script setup>
const features = [
  {
    name: "Track trades in real-time.",
    description:
      "Add and fetch trades from different exchanges, and monitor your profits and losses on each trade in real-time.",
    icon: "material-symbols:looks",
  },
  {
    name: "Analyze your portfolio.",
    description:
      "View total PNL for the current month, year, and overall, and track historical trends in PNL based on your previous data.",
    icon: "mdi:google-analytics",
  },
  {
    name: "Generate reports and charts.",
    description:
      "Access various reports and charts to help you make informed decisions about your investments.",
    icon: "material-symbols:auto-graph-rounded",
  },
  {
    name: "Calculate taxes.",
    description:
      "Calculate tax based on PNL at the year-end (only available for Norwegian users).",
    icon: "material-symbols:android-calculator",
  },
  {
    name: "Stay on top of your trades.",
    description:
      "Set alerts for your trades and receive email notifications when a trade passes a certain profit percentage.",
    icon: "material-symbols:notifications-active-outline",
  },
  {
    name: "Integrations.",
    description:
      "Support for a wide range of trading platforms and we’re constantly adding more. ",
    icon: "carbon:integration",
  },
];
</script>

<template>
  <div class="py-12 bg-white sm:py-16" id="product">
    <div class="px-6 mx-auto max-w-7xl lg:px-8">
      <div class="max-w-2xl mx-auto sm:text-center">
        <h2 class="text-base font-semibold leading-7 text-indigo-600">
          Everything you need
        </h2>
        <p
          class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Trade in many platforms? No problem.
        </p>
        <p class="mt-6 text-lg leading-8 text-gray-600">
          Manage your trades across multiple platforms with ease using
          VimiCrypto. Our platform supports a wide range of trading platforms
          and we’re constantly adding more. Stay on top of your investments and
          make informed decisions with VimiCrypto.
        </p>
      </div>
    </div>
    <div class="relative pt-16 overflow-hidden">
      <div class="px-6 mx-auto max-w-7xl lg:px-8">
        <LazyNuxtImg
          src="/homepage-screen-white.png"
          alt="App screenshot"
          class="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-gray-900/10"
          height="1442" />
        <div class="relative" aria-hidden="true">
          <div
            class="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]" />
        </div>
      </div>
    </div>
    <div class="px-6 mx-auto mt-16 max-w-7xl sm:mt-20 md:mt-24 lg:px-8">
      <dl
        class="grid max-w-2xl grid-cols-1 mx-auto text-base leading-7 text-gray-600 gap-x-6 gap-y-10 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
        <div
          v-for="feature in features"
          :key="feature.name"
          class="relative pl-9">
          <dt class="inline font-semibold text-gray-900">
            <UIcon
              :name="feature.icon"
              class="absolute w-5 h-5 text-indigo-600 left-1 top-1"
              aria-hidden="true" />
            {{ feature.name }}
          </dt>
          {{ " " }}
          <dd class="inline">{{ feature.description }}</dd>
        </div>
      </dl>
    </div>
  </div>
</template>
