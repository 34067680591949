<script setup>
import {
  RadioGroup,
  RadioGroupLabel,
  RadioGroupOption,
  provideUseId,
} from "@headlessui/vue";
import { CheckIcon } from "@heroicons/vue/20/solid";
import { useHomeStore } from "@/stores/HomeStore";

provideUseId(() => useId());

const frequencies = [
  { value: "monthly", label: "Monthly" },
  { value: "annually", label: "Annually" },
];
const tiers = [
  {
    name: "Basic",
    id: "tier-basic",
    href: "#",
    featured: false,
    price: { monthly: "$0", annually: "$0" },
    mainFeatures: [
      "Add transactions manually",
      "Real-time PNL tracking",
      "PNL summary",
      "Upto 3 alerts",
    ],
  },
  {
    name: "Premium",
    id: "tier-premium",
    href: "#",
    featured: true,
    price: { monthly: "$10", annually: "$100" },
    mainFeatures: [
      "Add transactions manually and via exchanges",
      "Real-time PNL tracking",
      "PNL summary",
      "Unlimited alerts",
      "Reports and charts",
      "Tax calculations (Norway only)",
    ],
  },
];

const frequency = ref(frequencies[0]);

const homeStore = useHomeStore();
const signUp = () => {
  homeStore.signIn = false;
};
</script>

<template>
  <div class="overflow-hidden isolate" id="pricing">
    <div class="flow-root pt-24 pb-16 bg-gray-900 sm:pt-32 lg:pb-0">
      <div class="px-6 mx-auto max-w-7xl lg:px-8">
        <div class="relative z-10">
          <h2
            class="max-w-4xl mx-auto text-5xl font-bold tracking-tight text-center text-white">
            Simple pricing, no long-term commitment
          </h2>
          <p
            class="max-w-2xl mx-auto mt-4 text-lg leading-8 text-center text-white/60">
            Compare our pricing plans to find the best fit for your needs, and
            upgrade to a more affordable plan if your needs become greater and
            complex.
          </p>
          <div class="flex justify-center mt-16">
            <RadioGroup
              v-model="frequency"
              class="grid grid-cols-2 p-1 text-xs font-semibold leading-5 text-center text-white rounded-full gap-x-1 bg-white/5">
              <RadioGroupLabel class="sr-only"
                >Payment frequency</RadioGroupLabel
              >
              <RadioGroupOption
                as="template"
                v-for="option in frequencies"
                :key="option.value"
                :value="option"
                v-slot="{ checked }">
                <div
                  :class="[
                    checked ? 'bg-indigo-500' : '',
                    'cursor-pointer rounded-full px-2.5 py-1',
                  ]">
                  <span>{{ option.label }}</span>
                </div>
              </RadioGroupOption>
            </RadioGroup>
          </div>
        </div>
        <div
          class="relative grid max-w-md grid-cols-1 mx-auto mt-10 gap-y-8 lg:mx-0 lg:-mb-14 lg:max-w-none lg:grid-cols-2">
          <svg
            viewBox="0 0 1208 1024"
            aria-hidden="true"
            class="absolute -bottom-48 left-1/2 h-[64rem] -translate-x-1/2 translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] lg:-top-48 lg:bottom-auto lg:translate-y-0">
            <ellipse
              cx="604"
              cy="512"
              fill="url(#d25c25d4-6d43-4bf9-b9ac-1842a30a4867)"
              rx="604"
              ry="512" />
            <defs>
              <radialGradient id="d25c25d4-6d43-4bf9-b9ac-1842a30a4867">
                <stop stop-color="#7775D6" />
                <stop offset="1" stop-color="#E935C1" />
              </radialGradient>
            </defs>
          </svg>
          <div
            class="hidden lg:absolute lg:inset-x-px lg:bottom-0 lg:top-4 lg:block lg:rounded-t-2xl lg:bg-gray-800/80 lg:ring-1 lg:ring-white/10"
            aria-hidden="true" />
          <div
            v-for="tier in tiers"
            :key="tier.id"
            :class="[
              tier.featured
                ? 'z-10 bg-white shadow-xl ring-1 ring-gray-900/10'
                : 'bg-gray-800/80 ring-1 ring-white/10 lg:bg-transparent lg:pb-14 lg:ring-0',
              'relative rounded-2xl',
            ]">
            <div class="p-8 lg:pt-12 xl:p-10 xl:pt-14">
              <h3
                :id="tier.id"
                :class="[
                  tier.featured ? 'text-gray-900' : 'text-white',
                  'text-sm font-semibold leading-6',
                ]">
                {{ tier.name }}
              </h3>
              <div
                class="flex flex-col gap-6 sm:flex-row sm:items-end sm:justify-between lg:flex-col lg:items-stretch">
                <div class="flex items-center mt-2 gap-x-4">
                  <p
                    :class="[
                      tier.featured ? 'text-gray-900' : 'text-white',
                      'text-4xl font-bold tracking-tight',
                    ]">
                    {{ tier.price[frequency.value] }}
                  </p>
                  <div class="text-sm leading-5">
                    <p :class="tier.featured ? 'text-gray-900' : 'text-white'">
                      USD
                    </p>
                    <p
                      :class="
                        tier.featured ? 'text-gray-500' : 'text-gray-400'
                      ">
                      {{ `Billed ${frequency.value}` }}
                    </p>
                  </div>
                </div>
                <a
                  :href="tier.href"
                  @click="signUp"
                  :aria-describedby="tier.id"
                  :class="[
                    tier.featured
                      ? 'bg-indigo-600 shadow-sm hover:bg-indigo-500 focus-visible:outline-indigo-600'
                      : 'bg-white/10 hover:bg-white/20 focus-visible:outline-white',
                    'rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
                  ]"
                  >Get Started</a
                >
              </div>
              <div class="flow-root mt-8 sm:mt-10">
                <ul
                  role="list"
                  :class="[
                    tier.featured
                      ? 'divide-gray-900/5 border-gray-900/5 text-gray-600'
                      : 'divide-white/5 border-white/5 text-white',
                    '-my-2 divide-y border-t text-sm leading-6 lg:border-t-0',
                  ]">
                  <li
                    v-for="mainFeature in tier.mainFeatures"
                    :key="mainFeature"
                    class="flex py-2 gap-x-3">
                    <CheckIcon
                      :class="[
                        tier.featured ? 'text-indigo-600' : 'text-gray-500',
                        'h-6 w-5 flex-none',
                      ]"
                      aria-hidden="true" />
                    {{ mainFeature }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="relative bg-gray-50 lg:pt-14">
      <div class="px-6 py-12 mx-auto max-w-7xl sm:py-16 lg:px-8"></div>
    </div>
  </div>
</template>
