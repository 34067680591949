<template>
  <footer class="bg-gray-800" aria-labelledby="footer-heading" id="company">
    <h2 id="footer-heading" class="sr-only">Footer</h2>
    <div class="px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:py-16 lg:px-8">
      <div class="xl:grid xl:grid-cols-3 xl:gap-8">
        <div class="grid grid-cols-3 gap-8 xl:col-span-2">
          <div class="mt-12 md:mt-0">
            <h3 class="text-base font-medium text-white">Support</h3>
            <ul role="list" class="mt-4 space-y-4">
              <li v-for="item in navigation.support" :key="item.name">
                <a
                  :href="item.href"
                  class="text-base text-gray-300 hover:text-white"
                  >{{ item.name }}</a
                >
              </li>
            </ul>
          </div>
          <!-- <div>
            <h3 class="text-base font-medium text-white">Company</h3>
            <ul role="list" class="mt-4 space-y-4">
              <li v-for="item in navigation.company" :key="item.name">
                <a
                  :href="item.href"
                  class="text-base text-gray-300 hover:text-white"
                  >{{ item.name }}</a
                >
              </li>
            </ul>
          </div>-->
          <div class="mt-12 md:mt-0">
            <h3 class="text-base font-medium text-white">Legal</h3>
            <ul role="list" class="mt-4 space-y-4">
              <li v-for="item in navigation.legal" :key="item.name">
                <a
                  :href="item.href"
                  class="text-base text-gray-300 hover:text-white"
                  >{{ item.name }}</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="mt-8 xl:mt-0">
          <h3 class="text-base font-medium text-white">
            Subscribe to our newsletter
          </h3>
          <p class="mt-4 text-base text-gray-300">
            The latest news, articles, and resources, sent to your inbox
            regularly.
          </p>
          <form class="mt-4 sm:flex sm:max-w-md">
            <label for="email-address" class="sr-only">Email address</label>
            <input
              type="email"
              name="email-address"
              id="email-address"
              autocomplete="email"
              required=""
              class="w-full min-w-0 px-4 py-2 text-base text-gray-900 placeholder-gray-500 bg-white border border-transparent rounded-md appearance-none focus:border-white focus:placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
              placeholder="Enter your email" />
            <div class="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
              <button
                type="submit"
                class="flex items-center justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md bg-primary-color-light-2 hover:bg-primary-color-light-1 focus:outline-none focus:ring-2 focus:ring-primary-color-light-2 focus:ring-offset-2 focus:ring-offset-gray-800">
                Subscribe
              </button>
            </div>
          </form>
        </div>
      </div>
      <div
        class="pt-8 mt-8 border-t border-gray-700 md:flex md:items-center md:justify-between">
        <div class="flex space-x-6 md:order-2">
          <a
            v-for="item in navigation.social"
            :key="item.name"
            :href="item.href"
            class="text-gray-400 hover:text-gray-300">
            <span class="sr-only">{{ item.name }}</span>
            <UIcon :name="item.icon" class="w-6 h-6" aria-hidden="true" />
          </a>
        </div>
        <p class="mt-8 text-base text-gray-400 md:order-1 md:mt-0">
          &copy; 2022 Vimi Pussewala Hewage. Org.nr: 929487125.
        </p>
      </div>
    </div>
  </footer>
</template>

<script setup>
const navigation = {
  support: [
    { name: "Pricing", href: "#pricing" },
    {
      name: "Documentation",
      href: "https://docs.vimicrypto.com/help/create-account.html",
    },
  ],
  legal: [
    { name: "Terms of service", href: "/terms" },
    { name: "Privacy policy", href: "/privacy" },
  ],
  social: [
    {
      name: "Twitter",
      href: "https://twitter.com/viminorge",
      icon: "ri:twitter-x-fill",
    },
  ],
};
</script>
